import { Img } from '../Img';
import { Heading } from '../Heading';
import React from 'react';

export default function UserProfile({
  userImage = 'images/img_depth_7_frame_0.png',
  userName = 'Awke vet',
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-[24%] md:w-full mb-3 gap-3 md:mb-0`}
    >
      <Img
        src={userImage}
        alt="Awke Vet"
        className="h-[222px] w-full rounded-[12px] object-cover"
      />
      <Heading
        size="texts"
        as="p"
        className="text-[16px] font-medium text-gray-900"
      >
        {userName}
      </Heading>
    </div>
  );
}
