import { combineReducers } from 'redux';

// import auth from './auth';

// const { contractCurrency, contractType, parameterConfig } = projectConfig;

export default combineReducers({
  // StartPage,
  // ProductsPage,
  // ArticlesPage,
  // ContactPage,
  // UsPage,
  // InternationalPage,
});
