import { Img } from '../components/Img';
import { Button } from '../components/Button';
import { Heading } from '../components/Heading';
import React from 'react';

export default function Home1Rowdescription() {
  return (
    <div className="mt-14 flex items-center justify-center gap-8">
      <Img
        src="images/img_depth_6_frame_0_280x400.png"
        alt="Image"
        className="h-[280px] w-[44%] rounded-[12px] object-contain md:w-full"
      />
      <div className="flex w-[72%] flex-col items-start gap-8 py-[22px] md:w-full sm:py-5">
        <div className="self-stretch">
          <div className="mb-2 flex">
            <Heading
              size="headingmd"
              as="h1"
              className="text-[48px] font-semibold tracking-[-2.00px] text-gray-900 md:text-[44px] sm:text-[38px]"
            >
              Agenda tu cita{' '}
            </Heading>
          </div>
        </div>
        <Heading
          size="texts"
          as="h2"
          className="w-[70%] text-[16px] font-normal leading-6 text-gray-900 md:w-full"
        >
          Conéctate con un experto en zeolitas: reserva tu consulta ahora para
          obtener asesoramiento personalizado y maximizar los beneficios de
          nuestros productos
        </Heading>
        <Button className="flex h-[34px] min-w-[202px] flex-row items-center justify-center rounded-[5px] border border-solid border-blue-500 px-[33px] text-center text-[16px] text-gray-900 sm:px-5">
          Reserva de hora
        </Button>
      </div>
    </div>
  );
}
