import React from 'react';

const sizes = {
  textxs: 'text-[14px] font-medium',
  texts: 'text-[16px] font-medium',
  headingxs: 'text-[14px] font-semibold',
  headings: 'text-[22px] font-semibold',
  headingmd: 'text-[48px] font-semibold md:text-[44px] sm:text-[38px]',
};

const Heading = ({
  children,
  className = '',
  size = 'textxs',
  as,
  ...restProps
}) => {
  const Component = as || 'h6';

  return (
    <Component
      className={`text-gray-900 font-inter ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
