import { Heading } from '../components/Heading';
import UserProfile from '../components/UserProfile';
import React, { Suspense } from 'react';

const data = [
  { userImage: 'images/img_depth_7_frame_0.png', userName: 'Awke vet' },
  {
    userImage: 'images/img_depth_7_frame_0_222x222.png',
    userName: 'Awke beauty',
  },
  { userImage: 'images/img_depth_7_frame_0_1.png', userName: 'Awke  xantina' },
  { userImage: 'images/img_depth_7_frame_0_222x210.png', userName: 'Otros ' },
];

export default function Home1Columnqueofrece() {
  return (
    <div className="mt-[34px] flex flex-col gap-4">
      <div className="flex px-4 py-3">
        <Heading
          size="headings"
          as="h3"
          className="mt-2 text-[22px] font-semibold text-gray-900"
        >
          Que ofrecemos
        </Heading>
      </div>
      <div className="ml-4 flex gap-3 md:ml-0">
        <Suspense fallback={<div>Loading feed...</div>}>
          {data.map((d, index) => (
            <UserProfile {...d} key={'home' + index} />
          ))}
        </Suspense>
      </div>
    </div>
  );
}
