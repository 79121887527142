import { Img } from '../Img';
// import  { Button } from '../Button';
import { Heading } from '../Heading';
import { Menu, Button } from 'antd';
import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu ,  AiOutlineHome,
  AiOutlineShop,
  AiOutlineFileText,
  AiOutlineUser,
  AiOutlineGlobal,
  AiOutlinePhone} from 'react-icons/ai';


export default function Header({ ...props }) {
  const [selectedKey, setSelectedKey] = useState('6');
  const [nav, setNav] = useState(false);
  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };


  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
const navItems = [
  { id: 1, text: 'Inicio', icon: <AiOutlineHome size={24} /> },
  { id: 2, text: 'Productos', icon: <AiOutlineShop size={24} /> },
  { id: 3, text: 'Artículos', icon: <AiOutlineFileText size={24} /> },
  { id: 4, text: 'Nosotros', icon: <AiOutlineUser size={24} /> },
  { id: 5, text: 'Internacional', icon: <AiOutlineGlobal size={24} /> },
  { id: 6, text: 'Contacto', icon: <AiOutlinePhone size={24} /> },
];

return (
  <div className='bg-white flex justify-between items-center h-24 mx-auto px-4 text-black'>
    {/* Logo */}
    <h1 className='w-full text-3xl font-bold text-[#00df9a]'>
      <Img
        src="images/img_header_logo.svg"
        alt="Header logo"
        className="h-[10vh] max-w-[144px] object-contain"
      />
    </h1>

    {/* Desktop Navigation */}
    <ul className='hidden md:flex'>
      {navItems.map(item => (
        <li
          key={item.id}
          className='flex items-center p-4 hover:bg-[#1AA3E5] rounded-xl m-2 cursor-pointer duration-300 hover:text-white'
        >
          {item.icon}
          <span className='ml-2'>{item.text}</span>
        </li>
      ))}
    </ul>

    {/* Mobile Navigation Icon */}
    <div onClick={handleNav} className='block md:hidden'>
      {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
    </div>

    {/* Mobile Navigation Menu */}
    <ul
      className={`fixed top-0 left-0 w-[60%] h-full border-r border-r-gray-900 bg-[#FFF] ease-in-out duration-500 ${nav ? 'translate-x-0' : 'translate-x-[-100%]'}`}
    >
      {/* Mobile Logo */}
      <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>
        <Img
          src="images/img_header_logo.svg"
          alt="Header logo"
          className="h-[10vh] max-w-[144px] object-contain"
        />
      </h1>

      {/* Mobile Navigation Items */}
      {navItems.map(item => (
        <li
          key={item.id}
          className='flex items-center p-4 border-b hover:bg-[#1AA3E5] duration-300 hover:text-white cursor-pointer'
        >
          {item.icon}
          <span className='ml-2'>{item.text}</span>
        </li>
      ))}
    </ul>
  </div>
);

 
}
