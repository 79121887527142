import { Img } from '../components/Img';
import React from 'react';

export default function Home1RowOne() {
  return (
    <div className="mt-4 px-4">
      <Img
        src="images/img_depth_6_frame_0.png"
        alt="Image"
        className="h-[480px] w-full rounded-[12px] object-cover md:h-auto"
      />
    </div>
  );
}
