// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Menu, Dropdown, Button, Collapse, theme } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { GlobalOutlined } from '@ant-design/icons';
import '../styles/tailwind.css';
import '../styles/index.css';
import '../styles/font.css';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
import { Heading } from '../components/Heading';
import { Img } from '../components/Img';
import Home1Columnqueofrece from './Home1Columnqueofrece';
import Home1RowOne from './Home1RowOne';
import Home1Rowdescription from './Home1Rowdescription';
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from 'react-accessible-accordion';

import StartPage from '../View/StartPage';
import ProductsPage from '../View/ProductsPage';
import ArticlesPage from '../View/ArticlesPage';
import UsPage from '../View/UsPage';
import InternationalPage from '../View/InternationalPage';
import ContactPage from '../View/ContactPage';

import logo from './logo.svg';

const App = () => {
  const [language, setLanguage] = useState('en');

  const handleMenuClick = (e) => {
    setLanguage(e.key);
  };

  const panelStyle = {
    marginBottom: 14,
    // borderRadius: 12,
    background: '#E8F0F2',
  };

  const getItems = (panelStyle) => [
    {
      key: '1',
      label: '¿Por qué Awkelite® funciona tan bien?',
      children: `
    Awkelite® es una presentación micronizada de Zeolita, que llamamos AMZ-A
    (Zeolitas Micronizadas Activadas - Awkelite®). Funciona de forma sistémica
    debido al tamaño de los fragmentos. Su tamaño es de poco menos de un
    tercio de una célula nerviosa, lo que le da el tamaño preciso para que
    pueda penetrar las membranas celulares y entrar en el torrente sanguíneo.

    La estructura molecular del panal es una estructura de la jaula con una
    carga negativa natural que actúa como un imán que une las toxinas a la
    estructura de la jaula, y otras que quedarán atrapadas dentro de la
    jaula. Por lo tanto, las toxinas atrapadas se neutralizan mientras aún
    están en su sistema y se expulsan a través de los canales de eliminación
    normales del cuerpo.
    `,
      style: panelStyle,
    },
    {
      key: '2',
      label: '¿Puedo tomar Awkelite® con otros suplementos?',
      children: `Se recomienda encarecidamente tomar Awkelite® con sus suplementos. Los fragmentos pueden unirse a algunos nutrientes y actuar como transportadores hasta que entran en contacto con toxinas que tienen cargas magnéticas más fuertes. En este punto, intercambiarán el nutriente por la toxina. Por lo tanto, los nutrientes se entregan a lugares que normalmente no podrían alcanzarlos fácilmente por sí solos, gracias a Awkelite®.`,
      style: panelStyle,
    },
    {
      key: '3',
      label: '¿Puedo mezclar Awkelite® con mis medicamentos?',
      children: `Awkelite® solo debe tomarse dos horas antes o después de tomar cualquier medicamento holístico.`,
      style: panelStyle,
    },
    {
      key: '4',
      label: '¿Cuánto tiempo debo tomar Awkelite®?',
      children: `Como se ha dicho en innumerables ocaciones, estamos rodeados de toxinas, en nuestra comida, en el agua y prácticamente todo lo que respiramos, comemos o bebemos. Awkelite® tiene como objetivo la desintoxicación pasiva a largo plazo y, dado que estamos expuestos a toxinas a diario, creemos que es bueno mantener el producto en el sistema en todo momento. Idealmente, Awkelite® es un producto de matriz a la tumba. Para casos crónicos de toxicidad, se recomienda una dosis más alta para ayudar en la desintoxicación. La dosis y la duración del uso dependen del caso. Se recomienda que trabaje con su proveedor de salud personal y / o se comunique con el especialista del equipo de Awkelite® para obtener más detalles.
`,
      style: panelStyle,
    },
    {
      key: '5',
      label:
        '¿Por qué la dosis de Awkelite® es diferente a la de otros productos de zeolita?',
      children: `El tamaño de nuestras partículas de zeolitas micronizadas se mide en micrones, de ahí el nombre. Poco más del 50% de las partículas presentes en Awkelite® logran pasar a la sangre y el resto permanece en el tracto digestivo. Esto debe ser así, ya que existen varios tipos de cáncer ubicados en el aparato digestivo (el de colon, por ejemplo, el más representativo en recidiva). Un producto demasiado espeso no llega al torrente sanguíneo y, por tanto, no ayudará a combatir el cáncer situado fuera del aparato digestivo (mamas en mujeres y próstata en hombres, por ejemplo).`,
      style: panelStyle,
    },
    {
      key: '6',
      label: '¿Cómo funciona Awkelite® con partículas virales?',
      children: `El poder de unión de las partículas Awkelite® barrerá las partículas virales y ralentizará el proceso de replicación viral. Esta limpieza adicional de las células del cuerpo conduce a una mejor función inmunológica. La combinación de una funcionalidad inmunológica más eficaz y la lentitud de la replicación del virus permite la combinación perfecta para apoyar y crear inmunidad y destruir las infecciones virales.
`,
      style: panelStyle,
    },
    {
      key: '7',
      label: '¿Se recomienda Awkelite® para mujeres embarazadas?',
      children: `Durante el embarazo, la madre comparte todo con su bebé, incluidas sus toxinas, por lo que sí, se recomienda limpiar su cuerpo constantemente durante el embarazo. También es muy recomendable desintoxicar primero el cuerpo antes de quedar embarazada, esta es otra razón para llevar la Awkelite® del útero a la tumba. Además, aumenta la probabilidad de quedar embarazada cuando el cuerpo libera toxinas. Asegúrese siempre de consultar con su profesional de la salud.`,
      style: panelStyle,
    },
    {
      key: '8',
      label: '¿Cómo puedo conocer el proceso de compra?',
      children: `Para información más detallada siempre puedes contactar a ventas@awke.cl`,
      style: panelStyle,
    },
  ];

  const languageMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="en">English</Menu.Item>
      <Menu.Item key="es">Español</Menu.Item>
    </Menu>
  );
  return (
    <>
      <Helmet>
        <title>Awke Chile</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="w-full bg-white-a700">
        <div className="bg-gray-50_01">
          <Header />
          <div className="mr-3 flex flex-col items-center p-4 md:mr-0">
            <div className="mx-auto w-full max-w-[932px]">
              <Home1RowOne />
              <Home1Rowdescription />
              <Home1Columnqueofrece />
              <div className="mt-4 flex px-4 py-3">
                <Heading
                  size="headings"
                  as="h4"
                  className="mt-2 text-[22px] font-semibold text-gray-900"
                >
                  Preguntas frecuentes
                </Heading>
              </div>
              <div className="mx-4 mt-4 flex flex-col gap-3 md:mx-0">
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  size="large"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  style={{
                    background: '#E8F0F2',
                   
                  }}
                  items={getItems(panelStyle)}
                />
                {/* <Accordion preExpanded={[0]} className="flex flex-col gap-3">
                  {accordionData.map((d, i) => (
                    <AccordionItem uuid={i} key={`expandablelistp${i}`}>
                      <div className="flex-1 rounded-[12px] bg-blue_gray-50 p-2">
                        <AccordionItemHeading className="w-full">
                          <AccordionItemButton>
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex flex-wrap items-center justify-between gap-5 py-2">
                                    <Heading
                                      size="headingxs"
                                      as="h5"
                                      className="text-[14px] font-semibold text-gray-900"
                                    >
                                      {d.label}
                                    </Heading>
                                    {props?.expanded ? (
                                      <Img
                                        src="images/img_arrow_down.svg"
                                        alt="Arrowdown"
                                        className="h-[20px] w-[20px]"
                                      />
                                    ) : (
                                      <Img
                                        src="images/img_arrow_down.svg"
                                        alt="Arrowdown"
                                        className="h-[20px] w-[20px]"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="mb-2">
                            <Heading as="h6" className="text-[14px] font-normal leading-[21px] text-gray-900_b2">
                             {d.children}
                            </Heading>
                          </div>
                        </AccordionItemPanel>
                      </div>
                    </AccordionItem>
                  ))}
                </Accordion> */}
                <div className="bg-gray-50">
                  <div>
                    <div className="flex">
                      <Heading
                        size="texts"
                        as="p"
                        className="font-bevietnampro text-[16px] font-medium text-gray-900_02"
                      >
                        Francisca Valdés
                      </Heading>
                    </div>
                    <div className="flex">
                      <Heading
                        as="p"
                        className="font-bevietnampro text-[14px] font-normal text-gray-600"
                      >
                        Sep 09, 2024
                      </Heading>
                    </div>
                  </div>
                  <Img
                    src="images/img_depth_6_frame_1.svg"
                    alt="Image"
                    className="mt-9 h-[20px] w-full md:h-auto"
                  />
                  <div className="mt-3 flex">
                    <Heading
                      size="texts"
                      as="p"
                      className="font-bevietnampro text-[16px] font-normal text-gray-900_02"
                    >
                      El producto es de excelente calidad y ha demostrado ser
                      efectivo para reducir los calambres.
                    </Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  //   return (
  //     <Router>
  //       <div style={{ width: '100%' }}>
  //         {/* <div style={{ display: 'flex', alignItems: 'center' }}>
  //           <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
  //           <Menu mode="horizontal" style={{ flex: 1 }}>
  //             <Menu.Item key="home">
  //               <Link to="/home">Inicio</Link>
  //             </Menu.Item>
  //             <Menu.Item key="products">
  //               <Link to="/products">Productos</Link>
  //             </Menu.Item>
  //             <Menu.Item key="articles">
  //               <Link to="/articles">Articulos</Link>
  //             </Menu.Item>
  //             <Menu.Item key="contact">
  //               <Link to="/contact">Contacto</Link>
  //             </Menu.Item>
  //             <Menu.Item key="us">
  //               <Link to="/us">Nosotros</Link>
  //             </Menu.Item>
  //             <Menu.Item key="international">
  //               <Link to="/international">Internacional</Link>
  //             </Menu.Item>
  //             <Menu.Item key="language" style={{ float: 'right' }}>
  //               <Dropdown overlay={languageMenu} trigger={['click']}>
  //                 <Button icon={<GlobalOutlined />} />
  //               </Dropdown>
  //             </Menu.Item>
  //           </Menu>
  //         </div> */}
  //  <Header />
  //         <Switch>
  //           <Route path="/home" component={StartPage} />
  //           <Route path="/products" component={ProductsPage} />
  //           <Route path="/articles" component={ArticlesPage} />
  //           <Route path="/contact" component={ContactPage} />
  //           <Route path="/us" component={UsPage} />
  //           <Route path="/international" component={InternationalPage} />
  //           <Route path="/" exact component={StartPage} />
  //         </Switch>
  //       </div>
  //     </Router>
  //   );
};

export default App;
